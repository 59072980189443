@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .title {
        @apply font-thin  text-white text-transparent bg-clip-text bg-gradient-to-t from-time-deep to-time-gold;
    }
}

html {
    background-color: #010116;
}

.line {
    height: 1px;
    background-color: #373737;
}